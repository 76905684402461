export const fallbackLng = 'pt';
export const languages = [fallbackLng, 'en'] as const;
export const defaultNS = 'common';
export const cookieName = 'i18next';

export const languagesMap = {
    pt: 'Português',
    en: 'English',
};

export function getOptions(lng = fallbackLng, ns = defaultNS) {
    return {
        // debug: true,
        supportedLngs: languages,
        fallbackLng,
        lng,
        fallbackNS: defaultNS,
        defaultNS,
        ns,
    };
}
