import { randomSessionId } from '@unleash/nextjs';
import acceptLanguage from 'accept-language';
import { NextRequest, NextResponse } from 'next/server';
import { cookieName, fallbackLng, languages } from './app/i18n/settings';

acceptLanguage.languages(languages as unknown as string[]);

export const AUTH_COOKIE = 'midas_auth';
export const USER_EMAIL_COOKIE = 'midas_user_email';

export const config = {
    matcher: [
        /*
         * Match all request paths except for the ones starting with:
         * - api (API routes)
         * - _next/static (static files)
         * - _next/image (image optimization files)
         * - favicon.ico (favicon file)
         */
        '/((?!api|_next/static|_next/image|favicon.ico|.*\\.svg|.*\\.png).*)',
    ],
};

export function middleware(req: NextRequest) {
    const isAbsoluteLanguagePath = req.nextUrl.pathname.length === 3;
    const lng = getLanguage(req);

    const sessionId =
        req.cookies.get('unleash-session-id')?.value || randomSessionId();

    let res: NextResponse;

    // adds default language prefix if there is none
    if (
        req.nextUrl.pathname !== '/' &&
        !languages.some((loc) => req.nextUrl.pathname.startsWith(`/${loc}`))
    ) {
        const url = new URL(`/${lng}${req.nextUrl.pathname}`, req.url);
        setSearchParams(url, req.nextUrl.searchParams);
        res = NextResponse.redirect(url);
    }
    // redirects to index with language if route is "/",
    else if (req.nextUrl.pathname === '/') {
        const resolvedLng = isAbsoluteLanguagePath
            ? req.nextUrl.pathname.slice(1)
            : getLanguage(req);

        const url = new URL(`/${resolvedLng}`, req.url);
        setSearchParams(url, req.nextUrl.searchParams);
        res = NextResponse.redirect(url);
    } else {
        res = NextResponse.next();
    }

    res.cookies.set('unleash-session-id', sessionId);

    return res;
}

function getLanguage(req: NextRequest) {
    let lng;

    if (req.cookies.has(cookieName)) {
        lng = acceptLanguage.get(req.cookies?.get(cookieName)?.value);
    }

    if (!lng) {
        lng = acceptLanguage.get(req.headers.get('Accept-Language'));
    }

    if (!lng) {
        lng = fallbackLng;
    }

    return lng;
}

function setSearchParams(url: URL, params: URLSearchParams) {
    params.forEach((value, key) => {
        url.searchParams.set(key, value);
    });
}
