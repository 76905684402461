'use client';

import { setDefaultOptions } from 'date-fns';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import {
    initReactI18next,
    useTranslation as useTranslationOrg,
} from 'react-i18next';
import { cookieName, getOptions, languages } from './settings';

function getDateFnsLocale(lng: (typeof languages)[number]) {
    if (lng === 'pt') {
        return import('date-fns/locale/pt-BR').then((x) => x.ptBR);
    } else if (lng === 'en') {
        return import('date-fns/locale/en-US').then((x) => x.enUS);
    }
}

const runsOnServerSide = typeof window === 'undefined';

const i18n = i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(
        resourcesToBackend(
            (language: string, namespace: string) =>
                import(`./locales/${language}/${namespace}.json`),
        ),
    );

i18n.on('languageChanged', (lng: (typeof languages)[number]) => {
    getDateFnsLocale(lng)?.then((locale) => {
        setDefaultOptions({ locale });
    });
});

i18n.init({
    ...getOptions(),
    lng: undefined, // let detect the language on client side
    detection: {
        order: ['path', 'htmlTag', 'cookie', 'navigator'],
    },
    preload: runsOnServerSide ? languages : [],
});

export function useTranslation(
    lng: string,
    ns?: string,
    options: { keyPrefix?: string } = {},
) {
    const ret = useTranslationOrg(ns, options);
    const { i18n } = ret;

    const [activeLng, setActiveLng] = useState(i18n.resolvedLanguage);
    const [cookies, setCookie] = useCookies([cookieName]);

    useEffect(() => {
        if (activeLng === i18n.resolvedLanguage) return;
        setActiveLng(i18n.resolvedLanguage);
    }, [activeLng, i18n.resolvedLanguage]);

    useEffect(() => {
        if (!lng || i18n.resolvedLanguage === lng) return;
        i18n.changeLanguage(lng);
    }, [lng, i18n]);

    useEffect(() => {
        if (cookies.i18next === lng) return;
        setCookie(cookieName, lng, { path: '/' });
    }, [lng, cookies.i18next]);

    if (runsOnServerSide && lng && i18n.resolvedLanguage !== lng) {
        i18n.changeLanguage(lng);
    }

    return ret;
}
