'use client';

import { redirect } from 'next/navigation';
import { useEffect, useState } from 'react';
import useAuth from '../use-auth';
import { useLng } from '../use-lng';

interface Options {
    authenticated?: boolean;
    sendTo?: string;
}

export default function useProtectPage({
    authenticated = true,
    sendTo = '/login',
}: Options = {}) {
    const [loading, setLoading] = useState(true);

    const lng = useLng();
    const { isAuthenticated } = useAuth();

    useEffect(() => {
        setLoading(false);

        if (isAuthenticated !== authenticated) {
            redirect(`/${lng}${sendTo}`);
        }
    }, []);

    return { loading };
}
