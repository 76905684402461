'use client';

import { AUTH_COOKIE } from '@/middleware';
import { useCookies } from 'react-cookie';

export default function useAuth() {
    const [cookies] = useCookies([AUTH_COOKIE]);

    return { isAuthenticated: !!cookies.midas_auth };
}
