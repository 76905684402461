'use client';
import { useTranslation } from '@/app/i18n/client';
import LanguageSelector from '@/components/language-selector';
import useProtectPage from '@/hooks/use-protect-page';
import clsx from 'clsx';
import Image from 'next/image';
import { Link, Separator } from 'react-aria-components';
import { LuTarget } from 'react-icons/lu';
import {
    MdCalculate,
    MdFileCopy,
    MdLock,
    MdOutlineQuestionMark,
    MdPrivacyTip,
    MdSearch,
    MdSettingsSuggest,
    MdShowChart,
    MdSupport,
} from 'react-icons/md';
import styles from './page.module.css';

interface Props {
    params: { lng: string };
}

export default function IndexPage({ params: { lng } }: Props) {
    const { t } = useTranslation(lng, 'index');

    useProtectPage({ authenticated: false, sendTo: '/campaigns' });

    return (
        <div
            className={clsx(
                'flex h-max min-h-full w-full flex-col justify-between lg:justify-start',
                styles.container,
            )}
        >
            <div className="flex w-full items-start justify-between bg-primary p-4">
                <div className="hidden w-48 lg:block">
                    <Link href={`/${lng}`}>
                        <Image
                            alt="Midas Trackr logo"
                            src="/logo-simple.svg"
                            width={24 * 1.5}
                            height={24 * 1.5}
                        />
                    </Link>
                </div>

                <h1 className="text-xl font-bold">
                    {t('welcome_to_midas_trackr')}
                </h1>

                <div className="flex items-center gap-2">
                    <Link href={`/${lng}/login`} className="underline">
                        {t('access_platform')}
                    </Link>

                    <LanguageSelector
                        className="hidden lg:block"
                        currentLanguage={lng as 'pt' | 'en'}
                    />
                </div>
            </div>

            <div className="my-16 flex flex-1 flex-col items-center justify-center gap-y-16 p-2 px-4 lg:justify-start lg:p-0 lg:px-4">
                <div className="flex w-full flex-1 items-center justify-center">
                    <div className="card flex w-full flex-col items-center gap-8 rounded-ee-xl rounded-es-[2rem] rounded-se-[2rem] rounded-ss-xl bg-white p-8 shadow-xl">
                        <h2 className="text-xl font-semibold">
                            {t('what_is_midas_trackr_question')}
                        </h2>

                        <p className="mt-16">
                            {t('what_is_midas_trackr_explanation')}
                        </p>

                        <div className="flex flex-col gap-4 lg:flex-row">
                            <InfoCard
                                icon={<MdShowChart size={36} />}
                                title={t('integration_in_real_time')}
                                description={t(
                                    'integration_in_real_time_description',
                                )}
                            />

                            <InfoCard
                                icon={<MdCalculate size={36} />}
                                title="CPC"
                                description={t('cpc_description')}
                            />

                            <InfoCard
                                icon={<MdSettingsSuggest size={36} />}
                                title={t('automatic_optimization')}
                                description={t(
                                    'automatic_optimization_description',
                                )}
                            />
                        </div>

                        <Link
                            href={`/${lng}/login`}
                            className="btn btn-primary px-8"
                        >
                            {t('access_platform')}
                        </Link>
                    </div>
                </div>

                <div className="flex w-full flex-1 items-center justify-center">
                    <div className="card flex w-full flex-col items-center gap-8 rounded-ee-xl rounded-es-[2rem] rounded-se-[2rem] rounded-ss-xl bg-white p-8 shadow-xl">
                        <h2 className="text-xl font-semibold">
                            {t('why_do_we_need_your_google_data_question')}
                        </h2>

                        <p className="mt-16">
                            {t('why_do_we_need_your_google_data_explanation')}
                        </p>

                        <div className="flex flex-col gap-4 lg:flex-row">
                            <InfoCard
                                icon={<MdSearch size={36} />}
                                title={t('detailed_analysis')}
                                description={t('detailed_analysis_description')}
                            />

                            <InfoCard
                                icon={<MdPrivacyTip size={36} />}
                                title={t('security_and_confidentiality')}
                                description={t(
                                    'security_and_confidentiality_description',
                                )}
                            />

                            <InfoCard
                                icon={<LuTarget size={36} />}
                                title={t('smart_optimization')}
                                description={t(
                                    'smart_optimization_description',
                                )}
                            />
                        </div>
                    </div>
                </div>

                <LanguageSelector
                    className="dropdown-top block lg:hidden [&>div>svg]:text-xl"
                    currentLanguage={lng as 'pt' | 'en'}
                    showCurrentLanguage
                />
            </div>

            <div className="mt-4 flex flex-col gap-2">
                <Separator className="block lg:hidden" />

                <div className="flex justify-between p-4 px-8 lg:hidden">
                    <Link className="underline" href={`/${lng}/info/policy`}>
                        {t('privacy')}
                    </Link>

                    <Link className="underline" href={`/${lng}/support`}>
                        {t('support')}
                    </Link>

                    <Link className="underline" href={`/${lng}/info/terms`}>
                        {t('terms')}
                    </Link>
                </div>

                <div className="hidden justify-between bg-primary p-4 px-8 lg:flex">
                    <p className="text-gray-700">
                        © 2024 - Midas Trackr - All Rights Reserved.
                    </p>

                    <div className="flex gap-4">
                        <Link
                            className="flex items-center gap-1"
                            href={`/${lng}/info/overview`}
                        >
                            <MdOutlineQuestionMark /> {t('help_center')}
                        </Link>

                        <Link
                            className="flex items-center gap-1"
                            href={`/${lng}/info/policy`}
                        >
                            <MdLock /> {t('privacy')}
                        </Link>

                        <Link
                            className="flex items-center gap-1"
                            href={`/${lng}/info/terms`}
                        >
                            <MdFileCopy /> {t('terms')}
                        </Link>

                        <Link
                            className="flex items-center gap-1"
                            href={`/${lng}/support`}
                        >
                            <MdSupport /> {t('support')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}

interface InfoCardProps {
    icon: React.ReactNode;
    title: string;
    description: string;
}

function InfoCard({ icon, title, description }: InfoCardProps) {
    return (
        <div className="flex flex-1 flex-col lg:flex-row">
            <div className="card flex flex-col items-center gap-4 rounded-ee-xl rounded-es-[2rem] rounded-se-[2rem] rounded-ss-xl bg-white p-8 shadow-xl">
                {icon}

                <h3 className="font-medium">{title}</h3>

                <p className="text-center">{description}</p>
            </div>
        </div>
    );
}
