'use client';

import { languages, languagesMap } from '@/app/i18n/settings';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { ReactNode } from 'react';
import { MdLanguage } from 'react-icons/md';

interface Props {
    className?: string;
    currentLanguage?: 'pt' | 'en';
    showCurrentLanguage?: boolean;
    icon?: ReactNode;
}

export default function LanguageSelector({
    className,
    currentLanguage,
    showCurrentLanguage,
    icon,
}: Props) {
    const pathname = usePathname();

    return (
        <div className={clsx('dropdown lg:dropdown-end', className)}>
            <div className="flex items-center" tabIndex={0} role="button">
                {icon ?? <MdLanguage className="m-1 text-2xl" />}

                {showCurrentLanguage &&
                    currentLanguage &&
                    languagesMap[currentLanguage]}
            </div>

            <ul
                tabIndex={0}
                className="menu dropdown-content z-[1] w-52 rounded-box bg-base-100 p-2 shadow-xl"
            >
                {languages.map((lng) => (
                    <li key={lng}>
                        <Link href={pathname.replace(currentLanguage!, lng)}>
                            {languagesMap[lng]}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}
